import React from 'react';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from 'universal-cookie';
import { handleResponseCode } from '../../../app/utilities/helpers';
import GetProxy from '../../../app/GetProxy';


const proxy = GetProxy()
const cookies = new Cookies();

export const regexCustomizationTable = createAsyncThunk(
    "posts/getCustomizationTable",
    async (props) => {
        const { pageNumber, itemsPerPage, payload } = props;
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        const res = await fetch(`${proxy}/api/v1/PII/RegexCustomization/getPIIRegexListWithSearchAndPagination/${pageNumber}/${itemsPerPage}`, {
            method: 'POST',
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                DTOP_API_TOKEN: Apitoken,
                USERNAME: User,
            },
            body: JSON.stringify(payload)
        }).then(res => {
            handleResponseCode(res);
            const result = res.json();
            return result;

        })
            .catch(err => {
                return err;
            })
        handleResponseCode(res);
        return res;

    });


export const regexCustomizationCount = createAsyncThunk(
    "posts/getCustomizationCount",
    async (props) => {
        const { payload } = props;
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        const res = await fetch(`${proxy}/api/v1/PII/RegexCustomization/getPIIRegexListCount`, {
            method: 'POST',
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                DTOP_API_TOKEN: Apitoken,
                USERNAME: User,
            },
            body: JSON.stringify(payload)
        }).then(res => {
            const result = res.json();
            return result;

        })
            .catch(err => {
                return err;
            })
        handleResponseCode(res);
        return res;

    });

export const RegexSave = createAsyncThunk(
    "posts/getRegexSave",
    async (props) => {
        const { payload } = props;
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        const res = await fetch(`${proxy}/api/v1/PII/RegexCustomization/saveNewPIIRegexCustomization`, {
            method: 'POST',
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                DTOP_API_TOKEN: Apitoken,
                USERNAME: User,
            },
            body: JSON.stringify(payload)
        }).then(res => {
            const result = res.json();
            return result;

        })
            .catch(err => {
                return err;
            })
        handleResponseCode(res);
        return res;

    });
export const RegexStatus = createAsyncThunk(
    "posts/getRegexStatus",
    async (props) => {
        const { payload } = props;
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        const res = await fetch(`${proxy}/api/v1/PII/RegexCustomization/setActiveOrInactiveStatus`, {
            method: 'POST',
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                DTOP_API_TOKEN: Apitoken,
                USERNAME: User,
            },
            body: JSON.stringify(payload)
        }).then(res => {
            const result = res.json();
            return result;

        })
            .catch(err => {
                return err;
            })
        handleResponseCode(res);
        return res;

    });
export const RegexDelete = createAsyncThunk(
    "posts/getRegexDelete",
    async (props) => {
        const { id } = props;
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        try {
            const res = await fetch(`${proxy}/api/v1/PII/RegexCustomization/deletePIIRegexCustomization/${id}`, {
                method: 'DELETE',
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    DTOP_API_TOKEN: Apitoken,
                    USERNAME: User,
                }
            });
            handleResponseCode(res);

            if (!res.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await res.json();
            return result;
        } catch (error) {
            return error;
        }
    }
);
export const WhitelistedTable = createAsyncThunk(
    "posts/getWhitelisted ",
    async (props) => {
        const { pageNumber, itemsPerPage, payload } = props;
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        const res = await fetch(`${proxy}/api/v1/PII/WhitelistedData/getWhiteListedListWithSearchAndPagination/${pageNumber}/${itemsPerPage}`, {
            method: 'POST',
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                DTOP_API_TOKEN: Apitoken,
                USERNAME: User,
            },
            body: JSON.stringify(payload)
        }).then(res => {
            const result = res.json();
            return result;

        })
            .catch(err => {
                return err;
            })
        handleResponseCode(res);
        return res;

    });

export const WhitelistedTableCount = createAsyncThunk(
    "posts/getWhitelistedCount",
    async (props) => {
        const { payload } = props;
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        const res = await fetch(`${proxy}/api/v1/PII/WhitelistedData/getWhiteListedListCount`, {
            method: 'POST',
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                DTOP_API_TOKEN: Apitoken,
                USERNAME: User,
            },
            body: JSON.stringify(payload)
        }).then(res => {
            const result = res.json();
            return result;

        })
            .catch(err => {
                return err;
            })
        handleResponseCode(res);
        return res;

    });

export const WhiteListSave = createAsyncThunk(
    "posts/getWhiteListSave",
    async (props) => {
        const { payload } = props;
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        const res = await fetch(`${proxy}/api/v1/PII/WhitelistedData/savePiiWhiteList`, {
            method: 'POST',
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                DTOP_API_TOKEN: Apitoken,
                USERNAME: User,
            },
            body: JSON.stringify(payload)
        }).then(res => {
            const result = res.json();
            return result;

        })
            .catch(err => {
                return err;
            })
        handleResponseCode(res);
        return res;

    });
export const WhiteListDelete = createAsyncThunk(
    "posts/getWhiteListDelete",
    async (props) => {
        const { id } = props;
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        try {
            const res = await fetch(`${proxy}/api/v1/PII/WhitelistedData/deletePiiWhiteList/${id}`, {
                method: 'DELETE',
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    DTOP_API_TOKEN: Apitoken,
                    USERNAME: User,
                }
            });
            handleResponseCode(res);

            if (!res.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await res.json();
            return result;
        } catch (error) {
            return error;
        }
    }
);
export const WhiteListStatus = createAsyncThunk(
    "posts/getWhiteListStatus",
    async (props) => {
        const { payload } = props;
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        const res = await fetch(`${proxy}/api/v1/PII/WhitelistedData/setActiveOrInactiveStatus`, {
            method: 'POST',
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                DTOP_API_TOKEN: Apitoken,
                USERNAME: User,
            },
            body: JSON.stringify(payload)
        }).then(res => {
            const result = res.json();
            return result;

        })
            .catch(err => {
                return err;
            })
        handleResponseCode(res);
        return res;

    });
export const PIIConfigurationSlice = createSlice({
    name: 'PIIConfigurationSlice',
    initialState: {
        regexCustomizationTableData: [],
        rCTableLoading: false,
        regexCustomizationDataCount: [],
        rCTableCountLoading: false,
        RegexSaveData: '',
        RegexSaveLoading: false,
        RegexStatusData: [],
        RegexStatusLoading: false,
        RegexTableReload: false,
        RegexDeleteData: '',
        RegexDeleteLoading: false,
        // Whitelisted Details
        WhitelistedTableData: [],
        WhitelistedLoading: false,
        WhitelistedTableCount: [],
        WhitelistedTableCountLoading: false,
        WhiteListSaveData: '',
        WhiteListSaveLoading: false,
        WhitelistedTableReload: false,
        WhiteListDeleteData: '',
        WhiteListDeleteLoading: false,
        WhiteListStatusData: [],
        WhiteListStatusLoading: false,
    },

    reducers: {
        setEmptyRegexDeleteData: (state) => {
            state.RegexDeleteData = '';
        },
        setEmptyRegexSaveData: (state) => {
            state.RegexSaveData = '';
        },
        setEmptyWhiteListSaveData: (state) => {
            state.WhiteListSaveData = '';
        },
        setEmptyWhiteListDeleteData: (state) => {
            state.WhiteListDeleteData = '';
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(regexCustomizationTable.pending, (state, action) => {
                state.rCTableLoading = true;
            })
            .addCase(regexCustomizationTable.fulfilled, (state, action) => {
                state.regexCustomizationTableData = action.payload;
                state.rCTableLoading = false;
                state.RegexTableReload = false;
            })
            .addCase(regexCustomizationTable.rejected, (state, action) => {
                state.rCTableLoading = false;
            })

            .addCase(regexCustomizationCount.pending, (state, action) => {
                state.rCTableCountLoading = true;
            })
            .addCase(regexCustomizationCount.fulfilled, (state, action) => {
                state.regexCustomizationDataCount = action.payload;
                state.rCTableCountLoading = false;
            })
            .addCase(regexCustomizationCount.rejected, (state, action) => {
                state.rCTableCountLoading = false;
            })

            .addCase(RegexSave.pending, (state, action) => {
                state.RegexSaveLoading = true;
            })
            .addCase(RegexSave.fulfilled, (state, action) => {
                state.RegexSaveData = 'Saved Successfully';
                state.RegexSaveLoading = false;
                state.RegexTableReload = true;
            })
            .addCase(RegexSave.rejected, (state, action) => {
                state.RegexSaveData = 'Save Failed';
                state.RegexSaveLoading = false;
            })

            .addCase(RegexStatus.pending, (state, action) => {
                state.RegexStatusLoading = true;
            })
            .addCase(RegexStatus.fulfilled, (state, action) => {
                state.RegexStatusData = action.payload;
                state.RegexStatusLoading = false;
                state.RegexTableReload = true;
            })
            .addCase(RegexStatus.rejected, (state, action) => {
                state.RegexStatusLoading = false;
            })

            .addCase(RegexDelete.pending, (state, action) => {
                state.RegexDeleteLoading = true;
            })
            .addCase(RegexDelete.fulfilled, (state, action) => {
                state.RegexDeleteData = 'Deleted Successfully';
                state.RegexDeleteLoading = false;
                state.RegexTableReload = true;
            })
            .addCase(RegexDelete.rejected, (state, action) => {
                state.RegexDeleteData = 'Delete Failed';
                state.RegexDeleteLoading = false;
            })
            // Whitelisted Details

            .addCase(WhitelistedTable.pending, (state, action) => {
                state.WhitelistedLoading = true;
            })
            .addCase(WhitelistedTable.fulfilled, (state, action) => {
                state.WhitelistedTableData = action.payload;
                state.WhitelistedLoading = false;
                state.WhitelistedTableReload = false;
            })
            .addCase(WhitelistedTable.rejected, (state, action) => {
                state.WhitelistedLoading = false;
            })

            .addCase(WhitelistedTableCount.pending, (state, action) => {
                state.WhitelistedTableCountLoading = true;
            })
            .addCase(WhitelistedTableCount.fulfilled, (state, action) => {
                state.WhitelistedTableCount = action.payload;
                state.WhitelistedTableCountLoading = false;
            })
            .addCase(WhitelistedTableCount.rejected, (state, action) => {
                state.WhitelistedTableCountLoading = false;
            })

            .addCase(WhiteListSave.pending, (state, action) => {
                state.WhiteListSaveLoading = true;
            })
            .addCase(WhiteListSave.fulfilled, (state, action) => {
                state.WhiteListSaveData = "Saved Successfully";
                state.WhiteListSaveLoading = false;
                state.WhitelistedTableReload = true;
            })
            .addCase(WhiteListSave.rejected, (state, action) => {
                state.WhiteListSaveData = "Save Failed";
                state.WhiteListSaveLoading = false;
            })

            .addCase(WhiteListDelete.pending, (state, action) => {
                state.WhiteListDeleteLoading = true;
            })
            .addCase(WhiteListDelete.fulfilled, (state, action) => {
                state.WhiteListDeleteData = 'Deleted Successfully';
                state.WhiteListDeleteLoading = false;
                state.WhitelistedTableReload = true;
            })
            .addCase(WhiteListDelete.rejected, (state, action) => {
                state.WhiteListDeleteData = 'Delete Failed';
                state.WhiteListDeleteLoading = false;
            })

            .addCase(WhiteListStatus.pending, (state, action) => {
                state.WhiteListStatusLoading = true;
            })
            .addCase(WhiteListStatus.fulfilled, (state, action) => {
                state.WhiteListStatusData = action.payload;
                state.WhiteListStatusLoading = false;
                state.WhitelistedTableReload = true;
            })
            .addCase(WhiteListStatus.rejected, (state, action) => {
                state.WhiteListStatusLoading = false;
            })

    },

});

export const { setEmptyRegexDeleteData, setEmptyRegexSaveData, setEmptyWhiteListSaveData, setEmptyWhiteListDeleteData } = PIIConfigurationSlice.actions;
export default PIIConfigurationSlice.reducer;