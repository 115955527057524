import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from 'universal-cookie';
import { handleResponseCode } from '../../app/utilities/helpers';
import GetProxy from '../../app/GetProxy';

const proxy = GetProxy()
const cookies = new Cookies();

export const useTreeGraphData = createAsyncThunk(
    "post/useTreeGraphData",
    async (props) => {
        const { payload } = props;
        const Apitoken = cookies.get("apitoken");
        const User = cookies.get("username");
        const res = await fetch(`${proxy}/api/v1/awsIam/getIAMTreeDataWithAccountId`, {
            method: 'POST',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                DTOP_API_TOKEN: Apitoken,
                USERNAME: User,
            },
            body: JSON.stringify(payload)
        }).then(res => {
            const result = res.json();
            return result;
        })
            .catch(err => {
                return err;
            })
        handleResponseCode(res);
        return res;

    });
export const initialState = {
    iamUserTreeData: [],
    iamUserTreeDataLoading: false,
}

export const UserGraphSlice = createSlice({
    name: 'UserGraphSlice',
    initialState,
    reducers: {
        resetUARGraph_InitalData: () => {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(useTreeGraphData.pending, (state) => {
                state.iamUserTreeDataLoading = true;
            })
            .addCase(useTreeGraphData.fulfilled, (state, action) => {
                state.iamUserTreeData = action.payload;
                state.iamUserTreeDataLoading = false;
            })
            .addCase(useTreeGraphData.rejected, (state) => {
                state.iamUserTreeDataLoading = false;
            })
    },
})
export default UserGraphSlice.reducer;
export const { resetUARGraph_InitalData } = UserGraphSlice.actions;
