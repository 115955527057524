import React from 'react';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from 'universal-cookie';
import { handleResponseCode } from '../../../app/utilities/helpers';
import GetProxy from '../../../app/GetProxy';
const cookies = new Cookies();
const proxy = GetProxy()

export const treeMapAccOpenApi = createAsyncThunk(
    "post/treeMapData",
    async (props) => {
        console.log("Coming to trmg");
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const response = await fetch(`${proxy}/api/v1/conformancePack/TRMG/getTRMGTreeMappedData/${props}`,
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        DTOP_API_TOKEN: apiToken,
                        USERNAME: username,
                    },
                }
            );
            handleResponseCode(response);
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
)

export const treeMapComplianceDrillDown = createAsyncThunk(
    "post/treeMapComplianceDrillDown",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { drillcurrentPage, drillPageLimit, drilltableTreePayload, dataTree } = props;
            const response = await fetch(`${proxy}/api/v1/conformancePack/TRMG/getComplianceListForTRMGTreeMappedDataWithSearch/${dataTree.accountId}/${dataTree.articleDescription}/${drillcurrentPage}/${drillPageLimit}`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        DTOP_API_TOKEN: apiToken,
                        USERNAME: username,
                    },
                    body: JSON.stringify(drilltableTreePayload),
                }
            );
            handleResponseCode(response);
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
)

export const treeMapComplianceDrillDownCount = createAsyncThunk(
    "post/treeMapComplianceDrillDownCount",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { drilltableTreePayload, dataTree } = props;
            const response = await fetch(`${proxy}/api/v1/conformancePack/TRMG/getComplianceForTRMGTreeMappedDataListCount/${dataTree.accountId}/${dataTree.articleDescription}`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        DTOP_API_TOKEN: apiToken,
                        USERNAME: username,
                    },
                    body: JSON.stringify(drilltableTreePayload),
                }
            );
            handleResponseCode(response);
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
)

export const treeMapNonComplianceDrillDown = createAsyncThunk(
    "post/treeMapNonComplianceDrillDown",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { drillcurrentPage, drillPageLimit, drilltableTreeOpenPayload, dataTree } = props;
            const response = await fetch(`${proxy}/api/v1/conformancePack/TRMG/getNonComplianceListForTRMGTreeMappedDataWithSearch/${dataTree.accountId}/${dataTree.articleDescription}/${drillcurrentPage}/${drillPageLimit}`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        DTOP_API_TOKEN: apiToken,
                        USERNAME: username,
                    },
                    body: JSON.stringify(drilltableTreeOpenPayload),
                }
            );
            handleResponseCode(response);
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
)

export const treeMapNonComplianceDrillDownCount = createAsyncThunk(
    "post/treeMapNonComplianceDrillDownCount",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { drilltableTreeOpenPayload, dataTree } = props;
            const response = await fetch(`${proxy}/api/v1/conformancePack/TRMG/getNonComplianceForTRMGTreeMappedDataListCount/${dataTree.accountId}/${dataTree.articleDescription}`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        DTOP_API_TOKEN: apiToken,
                        USERNAME: username,
                    },
                    body: JSON.stringify(drilltableTreeOpenPayload),
                }
            );
            handleResponseCode(response);
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
)
export const treeMapNotApplicableDrillDown = createAsyncThunk(
    "post/treeMapNotApplicableDrillDown",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { drillcurrentPage, drillPageLimit, drilltableTreePayload, dataTree } = props;
            const response = await fetch(`${proxy}/api/v1/conformancePack/TRMG/getNotApplicableListForTRMGTreeMappedDataWithSearch/${dataTree.accountId}/${dataTree.articleDescription}/${drillcurrentPage}/${drillPageLimit}`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        DTOP_API_TOKEN: apiToken,
                        USERNAME: username,
                    },
                    body: JSON.stringify(drilltableTreePayload),
                }
            );
            handleResponseCode(response);
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
)

export const treeMapNotApplicableDrillDownCount = createAsyncThunk(
    "post/treeMapNotApplicableDrillDownCount",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { drilltableTreePayload, dataTree } = props;
            const response = await fetch(`${proxy}/api/v1/conformancePack/TRMG/getNotApplicableForTRMGTreeMappedDataListCount/${dataTree.accountId}/${dataTree.articleDescription}`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        DTOP_API_TOKEN: apiToken,
                        USERNAME: username,
                    },
                    body: JSON.stringify(drilltableTreePayload),
                }
            );
            handleResponseCode(response);
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
)

export const chartDataApi = createAsyncThunk(
    "post/chartData",
    async (props) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const { payload, packName } = props;
            const response = await fetch(
                `${proxy}/api/v1/conformancePack/TRMG/GraphData/${packName}`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        DTOP_API_TOKEN: apiToken,
                        USERNAME: username,
                    },
                    body: JSON.stringify(payload),
                }
            );
            handleResponseCode(response);
            const data = await response.json();

            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
);
export const getResourceData = createAsyncThunk(
    "post/getResourceData",
    async ({ configRuleName, accountId, packName, pageNumber, itemsPerPage }) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const response = await fetch(`${proxy}/api/v1/conformancePack/getResourceDataAndDescriptionsPagination/${configRuleName}/${accountId}/${packName}/${pageNumber}/${itemsPerPage}`,
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        DTOP_API_TOKEN: apiToken,
                        USERNAME: username,
                    },
                }
            );
            handleResponseCode(response);
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
)
export const getResourceDataCount = createAsyncThunk(
    "post/getResourceDataCount",
    async ({ packName, accountId, compliantType, configRuleName }) => {
        const apiToken = cookies.get("apitoken");
        const username = cookies.get("username");
        try {
            const response = await fetch(`${proxy}/api/v1/conformancePack/count/${configRuleName}/${accountId}/${packName}/${compliantType}`,
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        DTOP_API_TOKEN: apiToken,
                        USERNAME: username,
                    },
                }
            );
            handleResponseCode(response);
            const data = await response.json();
            return response.ok ? data : Promise.reject(data);
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
)


const initialState = {
    treeMapData: [],
    mapDataApiLoading: false,
    trmgComplianceDrillDown: [],
    trmgComplianceDrillDownCount: [],
    trmgComplianceDrillDownLoading: false,
    trmgComplianceDrillDownCountLoading: false,
    trmgNonComplianceDrillDown: [],
    trmgNonComplianceDrillDownCount: [],
    trmgNonComplianceDrillDownLoading: false,
    trmgNonComplianceDrillDownCountLoading: false,
    trmgNotApplicableDrillDown: [],
    trmgNotApplicableDrillDownCount: [],
    trmgNotApplicableDrillDownLoading: false,
    trmgNotApplicableDrillDownCountLoading: false,
    getResourceDataStore: [],
    getResourceDataCountData: [],
    getResourceDataLoading: false,
    getResourceDataCountLoading: false,
}

export const TRMGSlice = createSlice({
    name: 'trmgSlice',
    initialState,

    reducers: {
        resetTRMGData: () => {
            return initialState;
        },
        resetdrillDownData: (state) => {
            state.trmgComplianceDrillDown = [];
            state.trmgComplianceDrillDownCount = [];
            state.trmgNonComplianceDrillDown = [];
            state.trmgNonComplianceDrillDownCount = [];
            state.trmgNotApplicableDrillDown = [];
            state.trmgNotApplicableDrillDownCount = [];
        },
    },
    extraReducers: (builder) => {
        builder

            //TreeMap Account Open Api
            .addCase(treeMapAccOpenApi.pending, (state, action) => {
                state.mapDataApiLoading = true;
            })
            .addCase(treeMapAccOpenApi.fulfilled, (state, action) => {
                state.treeMapData = action.payload;
                state.mapDataApiLoading = false;
            })
            .addCase(treeMapAccOpenApi.rejected, (state, action) => {
                state.mapDataApiLoading = false;
            })

            //TRMG Compliance DrillDown Api
            .addCase(treeMapComplianceDrillDown.pending, (state, action) => {
                state.trmgComplianceDrillDownLoading = true;
            })
            .addCase(treeMapComplianceDrillDown.fulfilled, (state, action) => {
                state.trmgComplianceDrillDown = action.payload;
                state.trmgComplianceDrillDownLoading = false;
            })
            .addCase(treeMapComplianceDrillDown.rejected, (state, action) => {
                state.trmgComplianceDrillDownLoading = false;
            })

            //TRMG Compliance DrillDown Count Api
            .addCase(treeMapComplianceDrillDownCount.pending, (state, action) => {
                state.trmgComplianceDrillDownCountLoading = true;
            })
            .addCase(treeMapComplianceDrillDownCount.fulfilled, (state, action) => {
                state.trmgComplianceDrillDownCount = action.payload;
                state.trmgComplianceDrillDownCountLoading = false;
            })
            .addCase(treeMapComplianceDrillDownCount.rejected, (state, action) => {
                state.trmgComplianceDrillDownCountLoading = false;
            })

            //TRMG Non Compliance DrillDown Api
            .addCase(treeMapNonComplianceDrillDown.pending, (state, action) => {
                state.trmgNonComplianceDrillDownLoading = true;
            })
            .addCase(treeMapNonComplianceDrillDown.fulfilled, (state, action) => {
                state.trmgNonComplianceDrillDown = action.payload;
                state.trmgNonComplianceDrillDownLoading = false;
            })
            .addCase(treeMapNonComplianceDrillDown.rejected, (state, action) => {
                state.trmgNonComplianceDrillDownLoading = false;
            })

            //TRMG Non Compliance DrillDown Count Api
            .addCase(treeMapNonComplianceDrillDownCount.pending, (state, action) => {
                state.trmgNonComplianceDrillDownCountLoading = true;
            })
            .addCase(treeMapNonComplianceDrillDownCount.fulfilled, (state, action) => {
                state.trmgNonComplianceDrillDownCount = action.payload;
                state.trmgNonComplianceDrillDownCountLoading = false;
            })
            .addCase(treeMapNonComplianceDrillDownCount.rejected, (state, action) => {
                state.trmgNonComplianceDrillDownCountLoading = false;
            })
            //TRMG Not Applicable DrillDown Api
            .addCase(treeMapNotApplicableDrillDown.pending, (state, action) => {
                state.trmgNotApplicableDrillDownLoading = true;
            })
            .addCase(treeMapNotApplicableDrillDown.fulfilled, (state, action) => {
                state.trmgNotApplicableDrillDown = action.payload;
                state.trmgNotApplicableDrillDownLoading = false;
            })
            .addCase(treeMapNotApplicableDrillDown.rejected, (state, action) => {
                state.trmgNotApplicableDrillDownLoading = false;
            })

            //TRMG Not Applicable DrillDown Count Api
            .addCase(treeMapNotApplicableDrillDownCount.pending, (state, action) => {
                state.trmgNotApplicableDrillDownCountLoading = true;
            })
            .addCase(treeMapNotApplicableDrillDownCount.fulfilled, (state, action) => {
                state.trmgNotApplicableDrillDownCount = action.payload;
                state.trmgNotApplicableDrillDownCountLoading = false;
            })
            .addCase(treeMapNotApplicableDrillDownCount.rejected, (state, action) => {
                state.trmgNotApplicableDrillDownCountLoading = false;
            })
            // TRMG chart
            .addCase(chartDataApi.pending, (state, action) => {
                state.chartDataLoading = true;
                state.error = "";
            })
            .addCase(chartDataApi.fulfilled, (state, action) => {
                state.chartData = action.payload;
                state.chartDataLoading = false;
            })
            .addCase(chartDataApi.rejected, (state, action) => {
                state.chartDataLoading = false;
            })
            // ResourceData
            .addCase(getResourceData.pending, (state, action) => {
                state.getResourceDataLoading = true;
                state.error = "";
            })
            .addCase(getResourceData.fulfilled, (state, action) => {
                state.getResourceDataStore = action.payload;
                state.getResourceDataLoading = false;
            })
            .addCase(getResourceData.rejected, (state, action) => {
                state.getResourceDataLoading = false;
            })
            // ResourceDataCount
            .addCase(getResourceDataCount.pending, (state, action) => {
                state.getResourceDataCountLoading = true;
                state.error = "";
            })
            .addCase(getResourceDataCount.fulfilled, (state, action) => {
                state.getResourceDataCountData = action.payload;
                state.getResourceDataCountLoading = false;
            })
            .addCase(getResourceDataCount.rejected, (state, action) => {
                state.getResourceDataCountLoading = false;
            });
    },
});

export const { resetTRMGData, resetdrillDownData } = TRMGSlice.actions;

export default TRMGSlice.reducer;