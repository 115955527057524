import React, { Suspense } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./assets/css/common.scss";
// import "./assets/css/style.scss"
import "./assets/css/internal.scss";
import "./assets/css/bootstrap_extended.scss";
import "./assets/css/common-overrides.scss";
import "./assets/css/observability.scss";
import 'typeface-montserrat';
import { pdfjs } from 'react-pdf';
// import "./assets/css/calender-custom.scss"
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { authRoutes, routes } from "./app/routes";
import { store } from "./app/store";
import { Provider } from "react-redux";
import ContainerLoader from "./app/common-components/ContainerLoader";
import PrivateRoute from "./PrivateRoute";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AccessDenied from "./app/common-components/AccessDenied";
import NotFound from "./app/common-components/NotFound";
import 'react-calendar/dist/Calendar.css';
import { useSelector, useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import CustomModal from "./app/common-components/CustomModal";
import ButtonComponent from "./app/common-components/ButtonComponent";
import { setSessionExtend, setsessionToken } from "./app/commonSlice";
import { resetLoggedUser, setPageLoading, setLoggedUser } from "./app/commonSlice";
import IdleTimercontainer from "./IdleTimercontainer";
import { refreshTokenApi } from "./Home/HomeApiSlice";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { setTimeoutId } from "./app/commonSlice";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

const modalBody = () => {


  const dispatch = useDispatch();
  const cookies = new Cookies();
  const navigate = useNavigate();
  const TimeoutId = useSelector(state => state.common.TimeoutId);

  const handleStayOnPage = () => {
    if (TimeoutId) {

      clearTimeout(TimeoutId);
      setTimeoutId(null); // Reset the timeout ID in the state
      dispatch(setSessionExtend(false));

      dispatch(refreshTokenApi()).then((data) => {
        if (data?.payload?.jwtToken) {
          console.log(data)
          cookies.set("apitoken", data?.payload?.jwtToken, { path: "/"});
          dispatch(setsessionToken(true));
        }
      });

    }

  };

  const logout = () => {

    if (TimeoutId) {
      clearTimeout(TimeoutId);
      setTimeoutId(null); // Reset the timeout ID in the state
      dispatch(setSessionExtend(false));
      sessionStorage.setItem("isLoggedin",false);
      const Apitoken = cookies.get("apitoken");
      const User = cookies.get("username");
      fetch("/auth?logout", {
        method: "GET",
        headers: {
          "DTOP_API_TOKEN": Apitoken,
          "USERNAME": User,
        },
        redirect: "manual",
      })
        .then(() => {
          handleLogout();
          dispatch(resetLoggedUser());
          dispatch(resetHomeData());
          dispatch(setsessionToken(false))
        })
        .catch((err) => {
          console.error(err);
        });
    }



  };
  const handleLogout = () => {
    cookies.remove("jwttoken");
    cookies.remove("Refersh");
    cookies.remove("apitoken");
    cookies.remove("username");
    cookies.remove("referstoken");
    dispatch(setLoggedUser({ userName: "", userRole: "", token: "" }));
    dispatch(setPageLoading(true));
    navigate("/auth");
  };
  return (
    <>
      <h4 className="delete-h border-bottom">Your session is about to expire</h4>
      <p>Do you want to stay on the page? or Logout</p>
      <div className="btnswrap btn-dlt" >
        <ButtonComponent className="btnStyle" onClick={() => { handleStayOnPage() }} text="Stay on Page" />
        <ButtonComponent className="btnStyle" onClick={() => { logout() }} text="Logout" />
      </div>
    </>
  );

}

function App() {
  const { loggedUser, pageLoading } = useSelector((state) => state.common);
  const { userName, userRole } = loggedUser;
  const CisoPageAccessRole = ['Ciso', 'Operation']
  const isAccessDenied = (routepath) => {
    const rolePaths = {
      Application_manager: ["configuration/administration/ldapConfig", "configuration/administration/smtpConfig", "configuration/administration/licenseConfig", "configuration/administration/userManagement", "configuration/administration/loggingConfig"],
    };

    return rolePaths[userRole]?.some(path => routepath.includes(path));
  };
  const renderRoutes = (allRoutes) => {
    return allRoutes.map((route) =>
      route.childNavs ? (
        renderRoutes(route.childNavs)
      ) : (
        <Route
          key={route.key}
          path={route.path}
          element={!userRole ? (
            <Navigate to="/auth" replace />
          ) : userRole == "Ciso" && route.path.includes("configuration/administration") ? (
            <Navigate to="/access-denied" replace />
          ) : userRole !== "" && !CisoPageAccessRole.includes(userRole) && route.path.includes("gnc/CISOInsights") || isAccessDenied(route.path) ? <Navigate to="/access-denied" replace /> : route.component}
        />
      )
    );
  };
  const sessionExtend = useSelector(state => state.common.sessionExtend);
  return (
    <Provider store={store}>
      <ToastContainer />
      <Suspense fallback={<ContainerLoader />}>
        <Router>
          {
            sessionExtend &&
            <CustomModal
              modalOpen={sessionExtend}
              closeBtn={false}
              modalBody={modalBody} />
          }
          <IdleTimercontainer />        
  
          <Routes>
          
            {authRoutes.map((route) => (
              
              <Route
                key={route.key}
                path={route.path}
                element={route.component}
                
              />
              
            ))}
           
            <Route path="/" element={<PrivateRoute />}>
     
              {renderRoutes(routes)}
              <Route path="/access-denied" element={<AccessDenied />}></Route>
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </Suspense>
    </Provider>
  );
}

export default App;
