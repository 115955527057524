import React from 'react';
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { propTypes } from 'react-bootstrap/esm/Image';
import Cookies from 'universal-cookie';
import { handleResponseCode } from '../../../app/utilities/helpers';
import GetProxy from '../../../app/GetProxy';
const cookies = new Cookies();
const proxy = GetProxy()

export const BuildMetricsChartApi = createAsyncThunk(
  "post/BuildMetricsChartApi",
  async (props) => {
    const { payload } = props;
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {

      const response = await fetch(`${proxy}/api/v1/productivityMetrics/buildMetric/Main`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const deploymentChartApi = createAsyncThunk(
  "post/deploymentChartApi",
  async (props) => {
    const { payload } = props;
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {

      const response = await fetch(`${proxy}/api/v1/productivityMetrics/deploymentMetrics/jenkinsDeployment/main`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const getDrilldownChartData = createAsyncThunk(
  "post/getDrilldownChartData",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {

      const { payload, URL } = props;
      const response = await fetch(
        `${URL}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const getDrilldownTableData = createAsyncThunk(
  "post/getDrilldownTableData",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {

      const { payload, URL, pageNumber, pageLimit } = props;
      const response = await fetch(
        `${URL}/${pageNumber}/${pageLimit}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const getDrilldownTableCountData = createAsyncThunk(
  "post/getDrilldownTableCountData",
  async (props) => {
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {

      const { payload, URL } = props;
      const response = await fetch(
        `${URL}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

export const staticCodeChartApi = createAsyncThunk(
  "post/staticCodeChartApi",
  async (props) => {
    const { payload } = props;
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {

      const response = await fetch(`${proxy}/api/v1/productivityMetrics/staticCodeAnalysis/main`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const codeQualityChartApi = createAsyncThunk(
  "post/codeQualityChartApi",
  async (props) => {
    const { payload } = props;
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    try {

      const response = await fetch(`${proxy}/api/v1/productivityMetrics/codeQualityAnalysisMainChart`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: Apitoken,
            USERNAME: User,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
const initialState = {
  buildMetricsData: [],
  buildMetricsLoading: false,
  buildMetricsError: false,
  deploymentChartData: [],
  deploymentChartLoading: false,
  deploymentMetricsError: false,
  staticCodeData: [],
  staticCodeLoading: false,
  staticCodeError: false,
  codeQualityeData: [],
  codeQualityLoading: false,
  codeQualityError: false,
  ProductivityDrillDownChart: [],
  ProductivityDrillDownChartLoading: false,
  ProductivityDrillDownTableData: [],
  ProductivityDrillDownTableDataLoading: {},
  ProductivityDrillDownTableCountData: [],
  ProductivityDrillDownTableCountDataLoading: false,
};

export const ProductivityMetricsSlice = createSlice({
  name: 'ProdMetricsSlice',
  initialState,

  reducers: {
    resetProductivityData: () => {
      return initialState;
    },
    setProductivityDrillDownChart: (state, action) => {
      state.ProductivityDrillDownChart = action.payload;
      state.ProductivityDrillDownTableData = action.payload;
      state.ProductivityDrillDownTableCountData = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(BuildMetricsChartApi.pending, (state, action) => {
        state.buildMetricsLoading = true;
        state.buildMetricsError = false;
      })
      .addCase(BuildMetricsChartApi.fulfilled, (state, action) => {
        state.buildMetricsLoading = false;
        state.buildMetricsData = action.payload;
      })
      .addCase(BuildMetricsChartApi.rejected, (state, action) => {
        state.buildMetricsLoading = false;
        state.buildMetricsError = true;
      })

      //deploymentMetrics
      .addCase(deploymentChartApi.pending, (state, action) => {
        state.deploymentChartLoading = true;
        state.deploymentMetricsError = false;
      })
      .addCase(deploymentChartApi.fulfilled, (state, action) => {
        state.deploymentChartLoading = false;
        state.deploymentChartData = action.payload;
      })
      .addCase(deploymentChartApi.rejected, (state, action) => {
        state.deploymentChartLoading = false;
        state.deploymentMetricsError = true;
      })

      //Static Code Analysis
      .addCase(staticCodeChartApi.pending, (state, action) => {
        state.staticCodeLoading = true;
        state.staticCodeError = false;
      })
      .addCase(staticCodeChartApi.fulfilled, (state, action) => {
        state.staticCodeLoading = false;
        state.staticCodeData = action.payload;
      })
      .addCase(staticCodeChartApi.rejected, (state, action) => {
        state.staticCodeLoading = false;
        state.staticCodeError = true;
      })

      //Code Quality Analysis
      .addCase(codeQualityChartApi.pending, (state, action) => {
        state.codeQualityLoading = true;
        state.codeQualityError = false;
      })
      .addCase(codeQualityChartApi.fulfilled, (state, action) => {
        state.codeQualityLoading = false;
        state.codeQualityeData = action.payload;
      })
      .addCase(codeQualityChartApi.rejected, (state, action) => {
        state.codeQualityLoading = false;
        state.codeQualityError = true;
      })

      //drilldown data

      .addCase(getDrilldownChartData.pending, (state, action) => {
        state.ProductivityDrillDownChartLoading = true;
        state.ProductivityDrillDownChart = [];
      })
      .addCase(getDrilldownChartData.fulfilled, (state, action) => {
        state.ProductivityDrillDownChartLoading = false;
        state.ProductivityDrillDownChart = action.payload;
      })
      .addCase(getDrilldownChartData.rejected, (state, action) => {
        state.ProductivityDrillDownChartLoading = false;
      })


      .addCase(getDrilldownTableData.pending, (state, action) => {
        state.ProductivityDrillDownTableDataLoading = true;
      })
      .addCase(getDrilldownTableData.fulfilled, (state, action) => {
        state.ProductivityDrillDownTableDataLoading = false;
        state.ProductivityDrillDownTableData = action.payload
      })
      .addCase(getDrilldownTableData.rejected, (state, action) => {
        state.ProductivityDrillDownTableDataLoading = false;
      })


      .addCase(getDrilldownTableCountData.pending, (state, action) => {
        state.ProductivityDrillDownTableCountDataLoading = true;
      })
      .addCase(getDrilldownTableCountData.fulfilled, (state, action) => {
        state.ProductivityDrillDownTableCountDataLoading = false;
        state.ProductivityDrillDownTableCountData = action.payload
      })
      .addCase(getDrilldownTableCountData.rejected, (state, action) => {
        state.ProductivityDrillDownTableCountDataLoading = false;
      })
  },

});

export const { resetProductivityData, setProductivityDrillDownChart } = ProductivityMetricsSlice.actions;
export default ProductivityMetricsSlice.reducer;