import React from "react";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { handleResponseCode } from "../../../app/utilities/helpers";
import { propTypes } from "react-bootstrap/esm/Image";
import Cookies from "universal-cookie";
import { post } from "jquery";
import GetProxy from "../../../app/GetProxy";

const proxy  = GetProxy()


const cookies = new Cookies();

export const policyCategorizationList = createAsyncThunk(
    "get/policyCategorizationList",
    async (props) => {
      const apiToken = cookies.get("apitoken");
      const username = cookies.get("username");
      try {
        const response = await fetch(`${proxy}/api/v1/awsIam/categorizedPolicyList`, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
        }
        );
        handleResponseCode(response);
        const data = await response.json();
        console.log('data: ', data);
  
        return response.ok ? data : Promise.reject(data);
      } catch (error) {
        console.error("Error:", error);
        throw error;
      }
    }
  )

  export const savePolicyCategorization = createAsyncThunk(
    "post/savePolicyCategorization",
    async (props) => {
      const apiToken = cookies.get("apitoken");
      const username = cookies.get("username");
      try {
        const { payload } = props;
        const response = await fetch(`${proxy}/api/v1/awsIam/savePolicyCategorization`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload)
        }
        );
        handleResponseCode(response);
        const data = await response.json();
        console.log('data: ', data);
        
  
        return response.ok ? data : Promise.reject(data);
      } catch (error) {
        console.error("Error:", error);
        throw error;
      }
    }
  )

  const policySlice = createSlice({
    name:'policyPrivilege',
    initialState:{
       policyCatgorizeLoading:false,
       policyCategorizationListData:{},
       savepolicyLoading:false,
      //  savePolicyCategorizationData:[]
    },
    extraReducers: (builder) => {
        builder
          //policyCatgorizeList
          .addCase(policyCategorizationList.pending, (state, action) => {
            state.policyCatgorizeLoading = true;
          })
          .addCase(policyCategorizationList.fulfilled, (state, action) => {
            state.policyCategorizationListData = action.payload;
            state.policyCatgorizeLoading = false;
          })
          .addCase(policyCategorizationList.rejected, (state, action) => {
            state.policyCatgorizeLoading = false;
          })

          //savePolicyCategorization
          .addCase(savePolicyCategorization.pending, (state, action) => {
            state.savepolicyLoading = true;
          })
          .addCase(savePolicyCategorization.fulfilled, (state, action) => {
            // state.savePolicyCategorizationData = action.payload;
            state.savepolicyLoading = false;
          })
          .addCase(savePolicyCategorization.rejected, (state, action) => {
            state.savepolicyLoading = false;
          })
    
      }
  })

  export default policySlice.reducer;
  