import React, { useState, useEffect } from "react";
import {
  resetLoggedUser,
  setPageLoading,
  setLoggedUser,
  setSessionExtend,
  setsessionToken,
} from "../commonSlice";
import {
  logoSmall,
  notification,
  user,
  logout,
  logoutnew,
  textlogout,
} from "../../assets/images/index";
import { updateSelectedApp, resetHomeData } from "../../Home/HomeSlice.js";
import { useLocation, useNavigate, Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setNavigationClicked } from "../commonSlice";
import { storageRemove } from "./Storage";
import Cookies from "universal-cookie";
import BreadCrumb from "./BreadCrumb";
import { get, isEmpty } from "lodash";
import _, { mapValues } from "lodash";
import { routes } from "../routes";
import { decrypt } from "../utilities/helpers.js";
import { setClickAble } from "../../gnc/RegulatoryCompliance/circular9/Circular9Slice.js";
import AlertBox from "./Alert.js";

const Container = () => {
  const cookies = new Cookies();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tabClick, setTabClick] = useState({});
  const [childMenuState, setChildMenuState] = useState({});
  const [lastTabAccess, setLastTabAccess] = useState(false);
  const [lastFocusByMouse, setLastFocusByMouse] = useState(false);
  const { userName, userRole } = useSelector(
    (state) => state.common.loggedUser
  );
  const { openAlert, alertType ,alertMessage} = useSelector(
    (state) => state.common.commonAlert
  );
  const pathName =
    get(location, "pathname", "") === "/gnc/ciso-view/Vulnerability" &&
    userRole !== "Ciso"
      ? ""
      : get(location, "pathname", "");
  const encoded = decrypt(userName);

  const onNavigate = (e, url) => {
    e.preventDefault();
    dispatch(setClickAble(null));
    dispatch(setPageLoading(true));
    navigate(url);
  };

  const logOut = (e) => {
    e.preventDefault();
    var newURL =
      pathName === "/gnc/ciso-view/Vulnerability"
        ? "/gnc/Vulnerability"
        : pathName;
    sessionStorage.setItem("lastVisitedURL", newURL);
    sessionStorage.setItem("lastVistedURL", newURL);
    sessionStorage.setItem("isLoggedin", false);
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");
    dispatch(setSessionExtend(false));
    fetch("/auth?logout", {
      method: "GET",
      headers: {
        DTOP_API_TOKEN: Apitoken,
        USERNAME: User,
      },
      redirect: "manual",
    })
      .then(() => {
        handleLogout();
        dispatch(resetLoggedUser());
        dispatch(resetHomeData());
        dispatch(setsessionToken(false));
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleLogout = () => {
    cookies.remove("jwttoken");
    cookies.remove("Refersh");
    cookies.remove("apitoken");
    cookies.remove("username");
    cookies.remove("referstoken");
    dispatch(setLoggedUser({ userName: "", userRole: "", token: "" }));
    dispatch(setPageLoading(true));
    dispatch(setsessionToken(false));
    navigate("/auth");
  };

  const handleChildMenuState = (path, value) => {
    setChildMenuState((childMenuState) => {
      const resetMenuState = mapValues({ ...childMenuState }, () => false);
      return { ...resetMenuState, [path]: value };
    });
  };

  const handleArrowNavigation = (event) => {
    const submenuOpen = document.querySelector(".nav.small");
    if (submenuOpen) {
      const submenuLinks = submenuOpen.querySelectorAll('[tabIndex="0"]');
      const currentLinkIndex = Array.from(submenuLinks).indexOf(
        document.activeElement
      );
      if (event.key === "ArrowUp") {
        event.preventDefault();
        const nextLinkIndex =
          currentLinkIndex === 0
            ? submenuLinks.length - 1
            : currentLinkIndex - 1;
        submenuLinks[nextLinkIndex].focus();
      } else if (event.key === "ArrowDown") {
        event.preventDefault();
        const nextLinkIndex = (currentLinkIndex + 1) % submenuLinks.length;
        submenuLinks[nextLinkIndex].focus();
      }
    }
  };

  const handleDocumentEvent = (event) => {
    if (event.type === "click") {
      setLastFocusByMouse(true);
      setLastTabAccess(false);
    } else if (event.type === "keydown" && event.key === "Tab") {
      setLastFocusByMouse(false);
      setLastTabAccess(true);
    }
  };

  const handleFocusIn = (input, lastFocusByMouse, lastTabAccess) => {
    // Remove classes from all inputs
    document
      .querySelectorAll("input, textarea, button,select")
      .forEach((el) => {
        el.classList.remove("tabClickStyle", "mouseClickStyle");
      });

    // Add class to the current input
    if (lastFocusByMouse) {
      input.classList.add("mouseClickStyle");
      console.log("Input field focused via mouse click");
    } else if (lastTabAccess) {
      input.classList.add("tabClickStyle");
      console.log("Input field focused via tab press");
    }
  };

  const handleFocusOut = (input) => {
    // Remove classes from the input
    input.classList.remove("tabClickStyle", "mouseClickStyle");
  };

  useEffect(() => {
    if (!isEmpty(tabClick)) {
      document.addEventListener("keydown", handleArrowNavigation);
      return () => {
        document.removeEventListener("keydown", handleArrowNavigation);
      };
    }
  }, [tabClick]);

  useEffect(() => {
    document.addEventListener("click", handleDocumentEvent);
    document.addEventListener("keydown", handleDocumentEvent);

    return () => {
      document.removeEventListener("click", handleDocumentEvent);
      document.removeEventListener("keydown", handleDocumentEvent);
    };
  }, []);

  useEffect(() => {
    const focusInHandler = (event) =>
      handleFocusIn(event.target, lastFocusByMouse, lastTabAccess);
    const focusOutHandler = (event) => handleFocusOut(event.target);

    document
      .querySelectorAll("input, textarea, button, select")
      .forEach((input) => {
        input.addEventListener("focusin", focusInHandler);
        input.addEventListener("focusout", focusOutHandler);
        input.addEventListener("mousemove", focusOutHandler);
      });

    return () => {
      document
        .querySelectorAll("input, textarea, button, select")
        .forEach((input) => {
          input.removeEventListener("focusin", focusInHandler);
          input.removeEventListener("focusout", focusOutHandler);
          input.addEventListener("mousemove", focusOutHandler);
        });
    };
  }, [lastFocusByMouse]);



  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight); 
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    };
 
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="wrapper_jr">
      <header
        onMouseLeave={() => {
          setTabClick({});
        }}
      >
        <div className="headerwrap">
          <div className="header-logo">
            <a href="#" tabIndex={0}>
              <img src={logoSmall} alt="DevOpsLab-code8-deploy" />
            </a>
          </div>
          <div className="nav small">
            <ul>
              {routes.map((route, index) => (
                <li
                  className={`dropdown-btn ${
                    pathName.includes(route?.path?.split("/")[0]) && "active"
                  } ${tabClick.index === index ? "tabInde" : ""} `}
                  key={index}
                >
                  <a
                    tabIndex={0}
                     className="navIcon"
                    href={route.iconNavigation ? `/${route.path}` : undefined}
                    onClick={(e) => {
                      route.iconNavigation && onNavigate(e, route.path);
                      dispatch(setNavigationClicked(true));
                      dispatch(updateSelectedApp({}));
                      setTabClick({});
                    }}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        route.iconNavigation && onNavigate(e, route.path);
                        setTabClick({ index: index });
                      }
                    }}
                  >
                    <img src={route.icon} alt={route.name} />
                  </a>

                  <div className="dropdown-container">               
                    {route?.childNavs?.length > 0 &&
                      route.name !== "Compliance" &&
                       (
                        <>
                           <strong className={`firstChildNav-heading ${route.name === "Home" ? "noborder" : ""}`}>
                            {route.path === "home"?(
                              <a
                              tabIndex={0}
                              className="navIcon"
                              href={
                                route.iconNavigation ? `/${route.path}` : undefined
                              }
                              onClick={(e) => {
                                route.iconNavigation && onNavigate(e, route.path);
                                if (route.path === "home") {
                                  dispatch(setNavigationClicked(true));
                                  dispatch(updateSelectedApp({}));
                                }

                                setTabClick({});
                              }}
                              >
                                {route.name}
                              </a>
                            ):(<>{route.name}</>)}
                            
                              
                            </strong>

                          {/* <hr className="firstChildNav-heading-hr" /> */}
                        </>
                      )}

                    {route?.childNavs?.map(
                      (firstChildNav, firstChildNavIndex) => {
            
          if (
                          firstChildNav.name === "Ciso Insights" &&
                          userRole !== "Ciso" &&
                          userRole !== "Operation"
                        ) {
                          return null; // Skip rendering this component
                        }

                        return (
                          !firstChildNav.isNotSideBarNavigation && (
                            <>
                              <a
                                tabIndex={tabClick.index === index ? 0 : 1}
                                href={
                                  !firstChildNav.navigationDisable
                                    ? `/${firstChildNav?.path}`
                                    : undefined
                                }
                                onClick={(e) => {
                                  !firstChildNav.navigationDisable &&
                                    onNavigate(e, firstChildNav?.path);
                                  storageRemove();
                                  dispatch(updateSelectedApp({}));
                                }}
                                onKeyDown={(e) => {
                                  if (e.keyCode === 13) {
                                    route.iconNavigation &&
                                      onNavigate(e, route.path);
                                  } else if (e.keyCode === 39) {
                                    setTabClick({
                                      index: index,
                                      firstChInd: firstChildNavIndex,
                                    });
                                    handleChildMenuState(
                                      firstChildNav.path,
                                      true
                                    );
                                  } else if (
                                    e.keyCode === 37 ||
                                    e.keyCode === 27
                                  ) {
                                    setTabClick({
                                      index: index,
                                      firstChInd: firstChildNavIndex,
                                    });
                                    handleChildMenuState(
                                      firstChildNav.path,
                                      false
                                    );
                                  }
                                }}
                                onMouseOver={() => {
                                  handleChildMenuState(
                                    firstChildNav.path,
                                    true
                                  );
                                  setTabClick({});
                                }}
                                onMouseLeave={() => {
                                  handleChildMenuState(
                                    firstChildNav.path,
                                    false
                                  );
                                  setTabClick({});
                                }}
                                className={
                                  firstChildNav.navigationDisableNotAllowed
                                    ? "not-allowed"
                                    : ""
                                }
                              >
                                {firstChildNav.text}
                              </a>
                              {/* Second Level childNavs */}
                              {firstChildNav?.childNavs &&
                                firstChildNav.name !== "Compliance" &&
                                firstChildNav.name !== "Regulatory" && (
                                  <div
                                  className={`${
                                    ["Efficiency", "observability"].includes(firstChildNav.name)
                                      ? "dropdown-container_child1"
                                      : ["Administration", "Settings", "CMDB"].includes(firstChildNav.name)
                                        ? screenWidth >= 1687 && screenHeight >= 796
                                          ? "dropdown-container_child1 sticky-header"  // Apply this if the resolution is lower
                                          : "dropdown-container_child1 " // Default class when resolution is higher
                                        : "dropdown-container_child1" // Fallback class
                                    }  custom_scrollNav ${firstChildNav.customDropContainerClass || ""} ${
                                      childMenuState[firstChildNav.path] ? "active " : ""
                                    }`}
                                  onMouseOver={() => handleChildMenuState(firstChildNav.path, true)}
                                  onMouseLeave={() => handleChildMenuState(firstChildNav.path, false)}
                                >
                                  <strong
                                    className="firstChildNav-heading  sticky-header"
                                  
                                  >
                                    {firstChildNav.name}
                                  </strong>
                                
                                  <hr className="firstChildNav-heading-hr" />
                                  {/* Render childNavs */}
                                  <div className="scrollable-links">
                                  {firstChildNav?.childNavs?.map((secondChildNav) => {
                                    // Skip rendering CISO Insights if not allowed
                                    if (
                                      secondChildNav.name === "CISO Insights" &&
                                      userRole !== "Ciso" &&
                                      userRole !== "Operation"
                                    ) {
                                      return null;
                                    }
                                
                                    return (
                                      !secondChildNav.isNotSideBarNavigation && (
                                        <a
                                          tabIndex={childMenuState[firstChildNav.path] ? 0 : 1}
                                          key={secondChildNav.path}
                                          href={
                                            !secondChildNav.navigationDisable
                                              ? `/${secondChildNav?.path}`
                                              : undefined
                                          }
                                          onClick={(e) => {
                                            if (!secondChildNav.navigationDisable) {
                                              onNavigate(e, secondChildNav?.path);
                                              storageRemove();
                                              setTabClick({});
                                              dispatch(updateSelectedApp({}));
                                            }
                                          }}
                                          className={`${
                                            secondChildNav.navigationDisableNotAllowed ? "not-allowed" : ""
                                          }`}
                                        >
                                          {secondChildNav.text}
                                        </a>
                                      )
                                    );
                                  })}
                                </div>
                                </div>
                                
                                
                                
                                )}
                            </>
                          )
                        );
                      }
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </header>

      <div className="mainwrap content">
        <div className="topwrap">
          {pathName !== "/access-denied" && (
            <div className="top_Header">
              <div className="topheader">
                <div className="topheader-left">
                  {/* <h3 id="topheader_text">
                    {getPageTitle(pathName, routes)}
                    </h3> */}
                  <BreadCrumb path={pathName} />
                  {/* {get(pageTitleMapper, `${pathName}.module`, "")}{" "}
                  </h3>
                  <h3 id="Page_header">
                    {get(pageTitleMapper, `${pathName}.pageTitle`, "")}
                  </h3> */}
                </div>
                <div className="topheader-right">
                  <div className="notify-block">
                    <a>
                      <img src={notification} />
                    </a>
                  </div>
                  <div className="userwrap">
                    <div className="logindes">
                      <h4 className={encoded === "sysadmin" ? "encoded" : ""}>
                        <a className="">{_.capitalize(encoded)}</a>
                      </h4>
                      <h6>
                        {encoded !== "sysadmin" ? (
                          <a className="userRole">
                            {userRole === "Ciso"
                              ? "CISO"
                              : userRole === "Department_head"
                              ? "Department Head"
                              : userRole === "Division_head"
                              ? "Division Head"
                              : userRole === "Application_manager"
                              ? "Application Manager"
                              : userRole}
                          </a>
                        ) : null}
                      </h6>
                    </div>
                    <div className="loginico">
                      <a>
                        <img src={user} />
                      </a>
                    </div>
                  </div>
                  <div className="logout" title-new="Logout">
                    <a onClick={(e) => logOut(e)}>
                      <img src={logout} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div
            className="wrapper"
            style={pathName === "/access-denied" ? { padding: 0 } : {}}
          >
            <Outlet />
          </div>
        </div>
      </div>
      <AlertBox alert={openAlert} type={alertType} message={alertMessage} />
    </div>
  );
};

export default Container;
