import { capitalize } from "lodash";
import React from "react";
import { Alert } from "react-bootstrap";

const AlertBox = (props) => {
  return (
    <Alert
      show={props.alert}
      variant={props.type}
      dismissible
      transition={false}
    >
      <div className="alert_img">
        <strong>{capitalize(props.type)}!</strong>
      </div>
      <div className="alert_msg_contain">
        <span className="alert-msg">
          {props.message instanceof Function ? props.message() : props.message}
        </span>
      </div>
    </Alert>
  );
};

export default AlertBox;
