import * as d3 from "d3";
import { map } from "lodash";
import moment from "moment";
import Cookies from "universal-cookie";
import CryptoJS from "crypto-js";
import { useDispatch } from "react-redux";
import { useRef } from "react";
import { setCommonAlert } from "../commonSlice";

const cookies = new Cookies();

export const getSelectedOptionsValue = (options = []) => {
  return map(options, "value");
};
export const getSelectedOptionsById = (options = []) => {
  const stringArray = map(options, "id");
  return stringArray.map((item) => item.toString());
  // return map(options, "id")
};
export const filterOptions = (optionsProps, searchText, setState) => {
  setState(
    optionsProps?.filter((option) => option.label?.includes(searchText))
  );
};
export const getPercentage = (value, total) => {
  let percentVal = 0;
  if (value !== 0 && total !== 0) {
    percentVal = (value / total) * 100;
    percentVal = percentVal.toFixed(2);
  }
  return percentVal;
};
export const truncate = (input, leng) => {
  if (input.length > leng) {
    return input.substring(0, leng) + "...";
  }
  return input;
};

export const responsivefy = (svg) => {
  // container will be the DOM element
  // that the svg is appended to
  // we then measure the container
  // and find its aspect ratio
  const container = d3.select(svg.node().parentNode),
    width = parseInt(svg.style("width"), 10),
    height = parseInt(svg.style("height"), 10),
    aspect = width / height;

  var doit;
  // set viewBox attribute to the initial size
  // control scaling with preserveAspectRatio
  // resize svg on inital page load
  svg
    .attr("viewBox", `0 0 ${width} ${height}`)
    .attr("preserveAspectRatio", "xMinYMid")
    .call(resize);

  // add a listener so the chart will be resized
  // when the window resizes
  // multiple listeners for the same event type
  // requires a namespace, i.e., 'click.foo'
  // api docs: https://goo.gl/F3ZCFr
  d3.select(window).on("resize." + container.attr("id"), resize);

  // this is the code that resizes the chart
  // it will be called on load
  // and in response to window resizes
  // gets the width of the container
  // and resizes the svg to fill it
  // while maintaining a consistent aspect ratio

  function resizedw() {
    const w = parseInt(container.style("width"));
    svg.attr("width", w);
    svg.attr("height", Math.round(w / aspect));
  }

  function resize() {
    clearTimeout(doit);
    doit = setTimeout(resizedw, 200);
  }
};
export function logOut() {
  window.location.href = "/auth"
  sessionStorage.setItem("isLoggedin", false)
  sessionStorage.setItem("lastVistedURL", window.location.pathname)
  cookies.remove("username");
  cookies.remove("apitoken");
  cookies.remove("jwttoken");
  cookies.remove("Refersh");
  cookies.remove("referstoken");
}

export const handleResponseCode = (response) => {
  try{
    if (response?.status === 403) {
      logOut();
    } else if (response?.redirected) {
      logOut();
    } else if (response?.status === 401) {
      logOut();
    }

    return response;
  }catch(error){
    console.error("An error occurred while handling the response:", error);
  }
}

export const resetLocalStorage = () => {
  const currentLocationBeforeDecode = window.location.href;
  const currentLocation = decodeURIComponent(currentLocationBeforeDecode).replaceAll(" ", "");
  const redirectedUrl = localStorage.getItem("redirectURL");
  
  if (redirectedUrl) {
    const redirectedUrlPath = redirectedUrl.split("#")[0];
  
    if (currentLocation == redirectedUrl || currentLocation == redirectedUrlPath) {
      localStorage.setItem("redirectURL", "");
    }
  }
}

export const severityFiltersMap = (Severity) => {
  if (Array.isArray(Severity)) {
    const severityFilters = Severity.map((severity, index) => ({
      label: severity,
      value: severity,
      id: index + 1
    }));
    return severityFilters;
  }
}
export const setRedirectPath = () => {
  const actualUrlBeforeDecode = window.location.href;
  const actualUrl = decodeURIComponent(actualUrlBeforeDecode).replaceAll(" ", "");
  let urlArray = actualUrl.split('#');
  let urlToRedirect = urlArray[0];
  if (actualUrl.includes("redirectedFromEmail")) {
    const allUrl = urlArray[1] && urlArray[1].split("&");
    let appName = allUrl ? allUrl[1] || "" : "";
    const appCode = appName.split("=")[1];
    const refId = allUrl[2] ? allUrl[2].split("=")[1] : "";
    localStorage.setItem("redirectURlAppNames", appCode);
    localStorage.setItem("redirectURL", actualUrl)
    localStorage.setItem("refIdForSelectedVulnerabilites", refId);
  }
}

function convertEpochTimeToMilliseconds(epochTime) {
  if (epochTime > 1e12) {
    return epochTime;
  } else {
    const epochTimeInMilliseconds = epochTime * 1000;
    return epochTimeInMilliseconds;
  }
}

export const convertEpochToDate = (givenDate) => {
  if (givenDate === "null") {
    return '-';
  }
  if (givenDate === 0) {
    return '-';
  }
  const dateObj = new Date(convertEpochTimeToMilliseconds(givenDate));

  const day = dateObj.toLocaleString('en-US', { day: '2-digit' });
  const month = dateObj.toLocaleString('en-US', { month: '2-digit' });
  const year = dateObj.toLocaleString('en-US', { year: 'numeric' });

  let hours = dateObj.getHours();
  const minutes = dateObj.getMinutes();

  if (hours >= 24) {
    hours -= 24;
  }

  return `${day}/${month}/${year}, ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
};

export const convertEpochToDate_withouttime = (epoch) => {
  const date = new Date(epoch);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

export function convertToEpoch(dateString) {
  const epochTime = Date.parse(dateString);
  return epochTime / 1000; // Convert milliseconds to seconds
}

export function formatDateYear(givenDate) {
  if (givenDate === "null") {
    return '-';
  }

  const dateObj = new Date(convertEpochTimeToMilliseconds(givenDate));

  const day = dateObj.toLocaleString('en-US', { day: '2-digit' });
  const month = dateObj.toLocaleString('en-US', { month: '2-digit' });
  const year = dateObj.toLocaleString('en-US', { year: 'numeric' });


  return `${day}/${month}/${year}`;
}

export const isEmptyObject = (object) => {
  return (
    object && Object.keys(object).length === 0 && object.constructor === Object
  );
}

export const capitalizeFirstLetter = (string) => {
  if (typeof string !== 'string' || string.length === 0) {
    return string; // Return the input as is
  }
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export const removeNullEntries = (data) => {
  const cleanedData = {};

  for (const key in data) {
    if (data[key] !== null) {
      cleanedData[key] = data[key];
    }
  }

  return cleanedData;
}

const initialStartDate = moment().startOf("month");
const initialEndDate = moment();

export const getDefaultSelectedDate = () => ({
  initialStartDate,
  initialEndDate,
});

export const encrypt = (data) => {
  const SECRET = "DevopsLabs2024";
  const key = CryptoJS.enc.Utf8.parse('En' + SECRET);
  const encrypted = CryptoJS.AES.encrypt(data, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
  }).toString();

  const urlSafeEncrypted = encrypted
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');

  return urlSafeEncrypted;
};


export const decrypt = (encryptedData) => {
  try {
      const key = "DevopsLabs2024";
      const dataToDecrypt = encryptedData.slice(4, -3);

      const secretKeyString = "En" + key;

      const keyBytes = CryptoJS.enc.Utf8.parse(secretKeyString);

      const base64Encrypted = dataToDecrypt
        .replace(/-/g, '+')
        .replace(/_/g, '/')
        .padEnd(dataToDecrypt.length + (4 - dataToDecrypt.length % 4) % 4, '=');

      const encryptedBytes = CryptoJS.enc.Base64.parse(base64Encrypted);

      const decrypted = CryptoJS.AES.decrypt(
          { ciphertext: encryptedBytes },
          keyBytes,
          {
              mode: CryptoJS.mode.ECB,
              padding: CryptoJS.pad.Pkcs7
          }
      );

      const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);
      return decryptedText;

  } catch (error) {
      console.error('Decryption error:', error);
      return null;
  }
};

//Common Alert
export const useCommonAlert = () => {
  // this is the way to call the function
  // callCommonAlert("error","Something went wrong. Please try again.");
  // hideCommonAlert();
  //type =success,warning,error


  const dispatch = useDispatch();
  const alertTimeoutRef = useRef(null);

  const callCommonAlert = (type,msg) => {
    if (alertTimeoutRef.current) {
      clearTimeout(alertTimeoutRef.current);
    }
    dispatch(setCommonAlert({
      openAlert: true,
      alertType: type,
      alertMessage: msg,
    }));
  };

  const hideCommonAlert = (duration = 5000) => {
    alertTimeoutRef.current = setTimeout(() => {
      dispatch(setCommonAlert({
        openAlert: false,
        alertType: "",
        alertMessage: "",
      }));
      alertTimeoutRef.current = null; // Reset the ref
    }, duration);
  };

  return {callCommonAlert, hideCommonAlert};
};
export const encryptData = (data) => {
  const SECRET = "DevopsLabs2024";
  const key = CryptoJS.enc.Utf8.parse("En" + SECRET);
  const encrypted = CryptoJS.AES.encrypt(data, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  }).toString();
 
  const urlSafeEncrypted = encrypted
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, ""); 
  return urlSafeEncrypted;
};
// Decryption Function
export const decryptData = (encryptedData) => {
  const SECRET = "DevopsLabs2024";
  const key = CryptoJS.enc.Utf8.parse("En" + SECRET);
  let base64String = encryptedData
    .replace(/-/g, "+")
    .replace(/_/g, "/") 
    .padEnd(
      encryptedData.length +
        (encryptedData.length % 4 === 0 ? 0 : 4 - (encryptedData.length % 4)),
      "="
    ); 
  const decrypted = CryptoJS.AES.decrypt(base64String, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
};