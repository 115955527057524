import { get } from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setLoggedUser, setPageLoading } from "./app/commonSlice";
import Container from "./app/common-components/Container";
import ContainerLoader from "./app/common-components/ContainerLoader";
import { userRestrictedAccess } from "./app/utilities/constants";
import {setRedirectPath} from "./app/utilities/helpers";
import Cookies from "universal-cookie";
import { setsessionToken } from "./app/commonSlice";
import { decrypt } from "./app/utilities/helpers";
import GetProxy from "./app/GetProxy";

const PrivateRoute = (props) => {
  const proxy = GetProxy()
  const cookies = new Cookies ();
  const location = useLocation();
  const pathName = get(location, "pathname", "");
  const { loggedUser, pageLoading } = useSelector((state) => state.common);
  const { userName, userRole ,token } = loggedUser;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const checkUserAccess = (userRole) => {
    if (
      document.title === "exora - Access Denied" &&
      pathName !== "/access-denied"
    )
      document.title = "exora";
    if (userRole?.trim() === "User") {
      const matchedPathIndex = userRestrictedAccess.indexOf(pathName);
      if (matchedPathIndex !== -1) {
        navigate("/access-denied");
        return false;
      }
    }
    pathName === "/" && navigate("/home");
    dispatch(setPageLoading(false));
  };
  const jwttoken = cookies.get("jwttoken");
  const refershtoken = cookies.get("Refersh");
  const sessionToken = useSelector(state => state.common.sessionToken);
  const getLoggedUser = async () => {
    const cookies = new Cookies();
    await fetch(`${proxy}/getTopHeader`, {
      method: "GET",
      redirect: "manual",
      cache: "no-store",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        Authorization: jwttoken,
        "Refresh-Token": refershtoken,
      },
    })
      .then((data) => {
        if (data.status === 200) {
          setRedirectPath();
          return data.json();
        } 
      }).then((response) => {
        if (response.refreshToken !== "undefined" && pathName !== "/" && refershtoken!==null) {
          const userName = response.encodedUsername;
          const userRole = response.role;
          const displayName = response.displayName;
          const token = response.token;
          const refreshToken = response.refreshToken;
          dispatch(setLoggedUser({userName,userRole,token,displayName}));
          sessionStorage.setItem("isLoggedin",true)
          if(sessionToken===false)
          {
            cookies.set("apitoken", token, { path: "/"});
          }

          cookies.set("username", userName, { path: "/"});
          cookies.set("referstoken", refreshToken, { path: "/"});
          return
        } else {
          cookies.remove("jwttoken");
          cookies.remove("Refersh");
          cookies.remove("apitoken");
          cookies.remove("username");
          cookies.remove("referstoken");
          sessionStorage.setItem("lastVistedURL", pathName);
          sessionStorage.setItem("isLoggedin",false);
          navigate("/auth");
        }
      })
      .catch((error) => {
        console.log("err", error);
        navigate("/auth");
      });
  };

  useEffect(() => {
    getLoggedUser();
  }, []);
  useEffect(() => {
    if (pathName === "/access-denied") document.title = "exora - Access Denied";
    userName !== "" && userRole !== "" && token !== "" && checkUserAccess(userRole);
  }, [pathName, userName, userRole, token, pageLoading]);

  return <>{pageLoading ? <ContainerLoader /> : <Container />}</>;
};

export default PrivateRoute;
