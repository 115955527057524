import React from "react";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { propTypes } from "react-bootstrap/esm/Image";
import Cookies from "universal-cookie";
import { handleResponseCode } from "../app/utilities/helpers";
import GetProxy from "../app/GetProxy";
const cookies = new Cookies();
const proxy = GetProxy()

export const backendConfig = createAsyncThunk(
  "get/backendConfig",
  async () => {
  const res = await fetch(
  `${proxy}/initial/licence/getSetUpStatus`,
  {
  method: "GET",
  headers: {
  Accept: "application/json",
  "Content-Type": "application/json",
  "CMTS-API-TOKEN": "fkO9eqvBkAwC2jLDsMY9L7Cnm9TGJEsXDudllrWLfFI=",
  },
  }
  ).then((resp) => resp.json());
  handleResponseCode(res);
  return res;
  }
  );
  export const passwordConfig = createAsyncThunk(
  "get/passwordConfig",
  async () => {
  const res = await fetch(
  `${proxy}/initial/setupConfig`,
  {
  method: "GET",
  headers: {
  Accept: "application/json",
  "Content-Type": "application/json",
  "CMTS-API-TOKEN": "fkO9eqvBkAwC2jLDsMY9L7Cnm9TGJEsXDudllrWLfFI=",
  },
  }
  ).then((resp) => resp.text());
  handleResponseCode(res);
  return res;
  }
  );
  export const licenseConfighome = createAsyncThunk(
  "get/licenseConfighome",
  async (props) => {
  const res = await fetch(
  `${proxy}/initial/licence/validation`,
  {
  method: "POST",
  headers: {
  Accept: "application/json",
  "Content-Type": "application/json",
  "CMTS-API-TOKEN": "fkO9eqvBkAwC2jLDsMY9L7Cnm9TGJEsXDudllrWLfFI=",
  },
  body: props,
  }
  ).then((resp) => resp.text());
  handleResponseCode(res);
  return res;
  }
  );
  export const wizardConfighome = createAsyncThunk(
  "get/wizardConfighome",
  async (props) => {
  const { payload } = props;
  const res = await fetch(
  `${proxy}/initial/setupConfig/wizard`,
  {
  method: "POST",
  headers: {
  Accept: "application/json",
  "Content-Type": "application/json",
  "CMTS-API-TOKEN": "fkO9eqvBkAwC2jLDsMY9L7Cnm9TGJEsXDudllrWLfFI=",
  },
  body: JSON.stringify(payload),
  }
  ).then((resp) => resp.text());
  handleResponseCode(res);
  return res;
  }
  );

export const buildSuccessRate = createAsyncThunk(
  "post/buildSuccessRate",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(`${proxy}/api/v1/Landing/buildSuccessRate`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          DTOP_API_TOKEN: apiToken,
          USERNAME: username,
        },
        body: JSON.stringify(payload),
      });
      handleResponseCode(response)
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const deploymentSuccessRate = createAsyncThunk(
  "post/deploymentSuccessRate",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(`${proxy}/api/v1/Landing/deploymentSuccessRate`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          DTOP_API_TOKEN: apiToken,
          USERNAME: username,
        },
        body: JSON.stringify(payload),
      });
      handleResponseCode(response)
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const flowPredictabilityRate = createAsyncThunk(
  "post/flowPredictabilityRate",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(`${proxy}/api/v1/Landing/flowPredictabilityRate`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          DTOP_API_TOKEN: apiToken,
          USERNAME: username,
        },
        body: JSON.stringify(payload),
      });
      handleResponseCode(response)
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
)
export const refreshTokenApi = createAsyncThunk(
  "get/refreshToken",
  async () => {
    const refreshToken = cookies.get("referstoken");
    const Apitoken = cookies.get("apitoken");
    const User = cookies.get("username");

    const res = await fetch(
      `${proxy}/refreshToken`,
      {
        method: "POST",
        headers: {
          Refreshtoken: refreshToken,
          DTOP_API_TOKEN: Apitoken,
          USERNAME: User,
        },

      }
    ).then((resp) => resp.json());
    console.log(res)
    return res;
  }
)

export const Homeapislice = createSlice({
  name: "homeapislice",
  initialState: {
    buildSuccessRatedata: 0,
    buildSuccessRateLoading: false,
    deploymentSuccessRatedata: 0,
    deploymentSuccessRateLoading: false,
    flowPredictabilityRatedata: 0,
    flowPredictabilityRateLoading: false,
  },

  reducers: {},
  extraReducers: (builder) => {
    builder
      //buildSuccessRate
      .addCase(buildSuccessRate.pending, (state, action) => {
        state.buildSuccessRateLoading = true;
      })
      .addCase(buildSuccessRate.fulfilled, (state, action) => {
        state.buildSuccessRatedata = action.payload;
        state.buildSuccessRateLoading = false;
      })
      .addCase(buildSuccessRate.rejected, (state, action) => {
        state.buildSuccessRateLoading = false;
        state.buildSuccessRatedata = 0;
      })
      //deploymentSuccessRate
      .addCase(deploymentSuccessRate.pending, (state, action) => {
        state.deploymentSuccessRateLoading = true;
      })
      .addCase(deploymentSuccessRate.fulfilled, (state, action) => {
        state.deploymentSuccessRatedata = action.payload;
        state.deploymentSuccessRateLoading = false;
      })
      .addCase(deploymentSuccessRate.rejected, (state, action) => {
        state.deploymentSuccessRateLoading = false;
      })
      //backendConfig
.addCase(backendConfig.pending, (state, action) => {
  state.backendConfigLoding = true;
  state.apiError = false;
  })
  .addCase(backendConfig.fulfilled, (state, action) => {
  state.backendConfigData = action.payload;
  state.backendConfigLoding = false;
  state.apiError = false;
  })
  .addCase(backendConfig.rejected, (state, action) => {
  state.backendConfigLoding = false;
  state.backendConfigData = true;
  state.apiError = true;
  })
  //passwordConfig
  .addCase(passwordConfig.pending, (state, action) => {
  state.passwordConfigLoding = true;
  state.apiError = false;
  })
  .addCase(passwordConfig.fulfilled, (state, action) => {
  state.passwordConfigData = action.payload;
  state.passwordConfigLoding = false;
  state.apiError = false;
  })
  .addCase(passwordConfig.rejected, (state, action) => {
  state.passwordConfigLoding = false;
  state.passwordConfigData = [];
  state.apiError = true;
  })
  //licenseConfighome
  .addCase(licenseConfighome.pending, (state, action) => {
  state.licenseConfighomeLoding = true;
  state.apiError = false;
  })
  .addCase(licenseConfighome.fulfilled, (state, action) => {
  state.licenseConfighomeData = action.payload;
  state.licenseConfighomeLoding = false;
  state.apiError = false;
  })
  .addCase(licenseConfighome.rejected, (state, action) => {
  state.licenseConfighomeLoding = false;
  state.licenseConfighomeData = [];
  state.apiError = true;
  })
  //wizardConfighome
  .addCase(wizardConfighome.pending, (state, action) => {
  state.wizardConfighomeLoding = true;
  state.apiError = false;
  })
  .addCase(wizardConfighome.fulfilled, (state, action) => {
  state.wizardConfighomeData = action.payload;
  state.wizardConfighomeLoding = false;
  state.apiError = false;
  })
  .addCase(wizardConfighome.rejected, (state, action) => {
  state.wizardConfighomeLoding = false;
  state.wizardConfighomeData = [];
  state.apiError = true;
  })
      //flowPredictabilityRate
      .addCase(flowPredictabilityRate.pending, (state, action) => {
        state.flowPredictabilityRateLoading = true;
      })
      .addCase(flowPredictabilityRate.fulfilled, (state, action) => {
        state.flowPredictabilityRatedata = action.payload;
        state.flowPredictabilityRateLoading = false;
      })
      .addCase(flowPredictabilityRate.rejected, (state, action) => {
        state.flowPredictabilityRateLoading = false;
        state.flowPredictabilityRatedata = 0;
      });
      
  },
});

export default Homeapislice.reducer;