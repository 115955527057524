import React from "react";
import { Modal, ModalHeader } from "react-bootstrap";
import "./custom-components.style.scss";

const CustomModal = (props) => {
  const {
    modalOpen,
    modalHide,
    closeBtn,
    modalCustomClass,
    modalBody,
    closeBtnFuc,
    downloadBtn,
    downloadFuc,
    size,
    Modalsize,
  } = props;
  return (
    <Modal
      show={modalOpen}
      onHide={modalHide}
      centered
      dialogClassName={modalCustomClass}
      animation={true}
      size={size}
      backdrop="static"
      className={Modalsize}
    >
      <div className="d-flex justify-content-end align-items-center">
        {downloadBtn && <button className="solid-btn mx-5 mt-2" onClick={downloadFuc}>Download</button>}
        {closeBtn && <button type="button" className={`${Modalsize===1?"UARbtn-close":"btn-close"}`} data-bs-dismiss="modal" aria-label="Close" onClick={() => closeBtnFuc()}>×</button>}
      </div>
      <Modal.Body>{modalBody()}</Modal.Body>
    </Modal>
  );
};

export default CustomModal;
