import React from "react";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";
import { handleResponseCode } from "../../../../app/utilities/helpers";
import GetProxy from "../../../../app/GetProxy";

const proxy = GetProxy()
const cookies = new Cookies();

export const passwordChangeApi = createAsyncThunk(
  "post/PasswordChange",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/LDAPDashboard/PasswordChangedData`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);
export const passwordChangeDueApi = createAsyncThunk(
  "post/PasswordChangeDue",
  async (props) => {
    const apiToken = cookies.get("apitoken");
    const username = cookies.get("username");
    try {
      const { payload } = props;
      const response = await fetch(
        `${proxy}/api/v1/LDAPDashboard/PasswordChangedDueData`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            DTOP_API_TOKEN: apiToken,
            USERNAME: username,
          },
          body: JSON.stringify(payload),
        }
      );
      handleResponseCode(response);
      const data = await response.json();

      return response.ok ? data : Promise.reject(data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
);

const initialState = {
  passwordChangeChart: [],
  passwordChangeChartLoading: false,
  passwordChangeDueChart: [],
  passwordChangeDueLoading: false,
  error: "",
}

export const iCMSlice = createSlice({
  name: 'icmSlice',
  initialState,

  reducers: {
    resetICMData: () => {
      return initialState;
    }
  },

  extraReducers: (builder) => {
    builder
      //PasswordChange
      .addCase(passwordChangeApi.pending, (state, action) => {
        state.passwordChangeChartLoading = true;
      })
      .addCase(passwordChangeApi.fulfilled, (state, action) => {
        state.passwordChangeChart = action.payload;
        state.passwordChangeChartLoading = false;
      })
      .addCase(passwordChangeApi.rejected, (state, action) => {
        state.passwordChangeChartLoading = false;
      })

      //PasswordChangeDue
      .addCase(passwordChangeDueApi.pending, (state, action) => {
        state.passwordChangeDueLoading = true;
      })
      .addCase(passwordChangeDueApi.fulfilled, (state, action) => {
        state.passwordChangeDueChart = action.payload;
        state.passwordChangeDueLoading = false;
      })
      .addCase(passwordChangeDueApi.rejected, (state, action) => {
        state.passwordChangeDueLoading = false;
      });

  },
});

export const { resetICMData } = iCMSlice.actions;

export default iCMSlice.reducer;